import classNames from 'classnames';
import { FormikHelpers } from 'formik';
import React, {
  FC, useState, MutableRefObject, useEffect, useCallback,
  useMemo,
} from 'react';
import {
  Folder,
  User,
  ChevronDown,
  MoreVertical,
  PlusCircle,
  MinusCircle,
  Edit,
  XCircle,
  CornerRightUp,
  CornerRightDown,
  Codesandbox,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { formatDateAndHour } from 'utils/dateManager';
import { formatName } from 'utils/helpers';
import { checkHaveAtLeastOnePermission } from 'utils/permissions';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import AssignUsers from 'components/base/AssignUsers';
import Button, { ButtonProps } from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';

import CreateUpdate from 'components/workOrder/CreateUpdate';
import MoreActions, { ActionsProps } from 'components/workOrder/MoreActions';

import { MissingIncinerationAuthorizationError, UnprocessableEntityError } from 'config/apiErrors';
import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import useUsers from 'hooks/useUsers';
import { useWorkshopTvView } from 'hooks/useWorkshopTvView';
import { ReceptionInfo } from 'models/CardInfo';
import { AssignUsersParams } from 'models/OrderAssign';
import {
  CollectionOrder, DeceasedPreparation, Handmade, MovementOrderInfo,
} from 'models/OrderInfo';
import { StatusCodes } from 'models/OrderStatus';
import OrderType from 'models/OrderType';
import { SimpleUser } from 'models/User';
import Workorder, { ChangeStatusFormProps } from 'models/Workorder';
import { AssignUsers as AssignUsersType } from 'models/WorkOrderFilter';

import WorkOrderInfo from '../Info';

import { AddDeceasedQRModal } from './AddDeceasedQR';
import ChangeStatus from './ChangeStatus/index';
import './index.scss';
import { FinishOrder } from './FinishOrder';
import { WorkshopCardHeader } from './WorkshopCardHeader';

type WorkOrderCardProps = {
  callBack: () => void;
  cardRef?: MutableRefObject<HTMLDivElement>;
  order: Workorder;
};

type Modals = {
  remove: ModalAttributes;
  start: ModalAttributes;
};

type ModalAttributes = {
  buttons?: ButtonProps[];
  body: JSX.Element;
  showCancel: boolean;
  title: string;
};

const WorkOrderCard: FC<WorkOrderCardProps> = ({
  order,
  callBack,
  cardRef,
}) => {
  const { t } = useTranslation();
  const { user, roleInfo } = useProvidedAuth();
  const [info, setInfo] = useState<AssignUsersParams>({});
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [showFinishOrder, setShowFinishOrder] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [showAddDeceasedQRModal, setShowAddDeceasedQRModal] = useState<boolean>(false);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [chosenModal, setChosenModal] = useState<keyof Modals>('start');
  const [userOptions, setUserOptions] = useState<Array<SimpleUser>>([]);
  const [assignedUsers, setAssignedUsers] = useState<Array<SimpleUser>>([]);
  const [orderWithInfo, setOrderWithInfo] = useState<Workorder>(order);
  const [displayDate, setDisplayDate] = useState<string>('');
  const { isTvView } = useWorkshopTvView();
  const { fetchWorkorderAssignableUsers, workorderAssignableUsers } = useUsers();

  const getInfo = () => {
    if (!loading) {
      setLoading(true);
      config.apiFunus.workOrder
        .getWorkOrderById(order.id)
        .then((response) => {
          setOrderWithInfo(response.data as Workorder);
          setAssignedUsers((response.data as Workorder).assignedUsers);
          formatInfo();
          setLoading(false);
          return response.data;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const sendStartOrder = useCallback((
    params: ChangeStatusFormProps,
    actions: FormikHelpers<ChangeStatusFormProps>,
  ) => {
    config.apiFunus.workOrder
      .changeStatus(order.id, {
        ...params,
        changeState: true,
        infoQr: orderWithInfo.infoQr || {},
      })
      .then(() => {
        successCloseAndResetModal(t('order.started'));
        actions.setSubmitting(false);
        return true;
      })
      .catch((error) => {
        if (error instanceof UnprocessableEntityError
          || error instanceof MissingIncinerationAuthorizationError) {
          showErrorToast(t(error.message));
        } else {
          showErrorToast(t('order.notStarted'));
        }
        actions.setSubmitting(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.id, orderWithInfo.infoQr, t]);

  const sendAddUsersRequest = useCallback((toSend: AssignUsersType) => {
    setLoadingModal(true);
    config.apiFunus.workOrder
      .assignUsers(toSend)
      .then((res) => {
        successCloseAndResetModal(t('order.usersUpdated'));
        setLoadingModal(false);
        if (orderWithInfo.info) {
          getInfo();
        }
        return res;
      })
      .catch(() => {
        showErrorToast(t('order.usersError'));
        setLoadingModal(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderWithInfo.info, t]);

  const deleteWorkorder = useCallback(() => {
    config.apiFunus.workOrder
      .deleteWorkOrder(order.id)
      .then(() => successCloseAndResetModal(t('order.removed')))
      .catch(() => {
        showErrorToast(t('order.removeError'));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.id, t]);

  const closeAndResetModal = () => {
    setShowConfigModal(false);
    setInfo({});
  };

  const successCloseAndResetModal = (success: string) => {
    closeAndResetModal();
    showSuccessToast(success);
    callBack();
  };

  useEffect(() => {
    if (order.status === StatusCodes.PENDING) {
      setDisplayDate(formatDateAndHour(order.createdDate));
    } else if (order.status === StatusCodes.IN_PROGRESS) {
      setDisplayDate(formatDateAndHour(order.startDate));
    } else if (order.status === StatusCodes.COMPLETED && order.finishDate) {
      setDisplayDate(formatDateAndHour(order.finishDate));
    }
  }, [order]);

  const addUsers = useCallback(() => {
    const userIds = assignedUsers.map(({ id }) => id);
    sendAddUsersRequest({
      coordinatorComment: info?.comment,
      deposit: info?.deposit,
      driver: info?.driver,
      userIds,
      vehicle: info?.vehicle,
      workOrderId: Number(order?.id),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedUsers, info?.comment, info?.deposit, info?.driver, info?.vehicle, order?.id]);

  const generateAcceptButton: (
    onClick?: () => void,
    disabled?: boolean
  ) => ButtonProps = useCallback((onClick, disabled) => ({
    color: 'primary',
    disabled,
    id: 'accept',
    onClick: () => {
      if (onClick) {
        onClick?.();
        setShowAssignModal(false);
      }
      return true;
    },
    text: t('common.accept'),
    type: 'button',
  }), [t]);

  const buttonsDeleteModal = useMemo(
    () => [generateAcceptButton(deleteWorkorder)],
    [deleteWorkorder, generateAcceptButton],
  );
  const buttonsAddUsersModal = useMemo(
    () => [generateAcceptButton(addUsers)],
    [addUsers, generateAcceptButton],
  );

  const modals: Modals = useMemo(() => ({
    remove: {
      body: <div>{t('order.removeSure')}</div>,
      buttons: buttonsDeleteModal,
      showCancel: true,
      title: t('order.remove'),
    },
    start: {
      body: <ChangeStatus order={orderWithInfo} onSubmit={sendStartOrder} />,
      showCancel: false,
      title: t('order.startOrder'),
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [buttonsDeleteModal, orderWithInfo, t]);

  const operatorAssigned = roleInfo.isOperator
    && !roleInfo.isWorkshopAdmin
    && !!order?.assignedUsers?.find(({ id }) => id === user?.id);

  const openConfigModal = useCallback((key: keyof Modals) => {
    const canStartOrFinish = checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE,
        SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_WRITE,
      ],
      user?.role?.permissions,
    );

    if (canStartOrFinish) {
      setChosenModal(key);
      getInfo();
      setShowConfigModal(true);
    } else if (roleInfo.isOperator) { // TODO limpiar código que nunca se ejecuta
      let userIds = order.assignedUsers.map(({ id }) => id);
      if (operatorAssigned) {
        userIds = userIds.filter((id) => id !== user?.id);
      } else {
        userIds.push(user?.id as number);
      }

      sendAddUsersRequest({ userIds, workOrderId: Number(order?.id) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, operatorAssigned, order?.assignedUsers, order?.id, roleInfo?.isOperator]);

  const displayFinishModal = useCallback(
    () => {
      const canStartOrFinish = checkHaveAtLeastOnePermission(
        [
          SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_WRITE,
          SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_WRITE,
        ],
        user?.role?.permissions,
      );
      if (canStartOrFinish) {
        getInfo();
        setShowFinishOrder(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const getMoreActionsOptions = () => {
    let options: Array<ActionsProps> = [];

    const isAssignedToCurrentUser = order.assignedUsers
      .some((assigned) => assigned.id === user?.id);
    const canAddDeceasedQr = order.type === OrderType.PICKUP
      && order.status === StatusCodes.IN_PROGRESS
      && isAssignedToCurrentUser
      && order.canProvideQr;

    if (
      order.status === StatusCodes.PENDING
      && order.assignedUsers.some((assigned) => assigned.id === user?.id)
    ) {
      options.push({
        icon: <CornerRightUp />,
        key: 'start',
        onClick: () => openConfigModal('start'),
        text: t('order.startOrder'),
      });
    }

    const canFinish = order.type !== OrderType.PICKUP
      ? order.status === StatusCodes.IN_PROGRESS
      && (roleInfo.isWorkshopAdmin || operatorAssigned)
      : order.status === StatusCodes.IN_PROGRESS
      && (roleInfo.isWorkshopAdmin || operatorAssigned) && !canAddDeceasedQr;
    if (
      canFinish
    ) {
      options.push({
        icon: <CornerRightDown />,
        key: 'finish',
        onClick: displayFinishModal,
        text: t('order.finishOrder'),
      });
    }

    if (
      (order.type === OrderType.HANDBOOK
        || order.type === OrderType.HANDBOOK_CEMETERY)
      && roleInfo.isWorkshopAdmin
    ) {
      options = options.concat([
        {
          icon: <Edit />,
          key: 'edit',
          onClick: () => setShowEdit(true),
          text: t('common.edit'),
        },
        {
          icon: <XCircle />,
          key: 'remove',
          onClick: () => openConfigModal('remove'),
          text: t('common.remove'),
        },
      ]);
    }

    if (canAddDeceasedQr) {
      options.push({
        icon: <Codesandbox />,
        key: 'deceasedQR',
        onClick: () => setShowAddDeceasedQRModal(true),
        text: t('order.addDeceasedQR'),
      });
    }

    if (options.length === 0) {
      options.push({
        key: 'nothing',
        onClick: () => undefined,
        text: t('order.noOptions'),
      });
    }

    return options;
  };

  const formatInfo = () => {
    if (order.type === OrderType.PICKUP) {
      setInfo({
        comment: (orderWithInfo.info as CollectionOrder).coordinatorComment || '',
        deposit: (orderWithInfo.info as CollectionOrder).deposit,
        driver: (orderWithInfo.info as CollectionOrder).driver || '',
        vehicle: (orderWithInfo.info as CollectionOrder).vehicle || '',
      });
    } else if (order.type === OrderType.RECEPTION) {
      setInfo({
        comment: (orderWithInfo.info as ReceptionInfo).coordinatorComment || '',
      });
    } else if (order.type === OrderType.PREPARATION) {
      setInfo({
        deposit: (orderWithInfo.info as DeceasedPreparation).deposit,
        vigil: (orderWithInfo.info as DeceasedPreparation).vigil,
      });
    } else if (order.type === OrderType.HANDBOOK) {
      setInfo({
        vehicle: (orderWithInfo.info as Handmade).vehicle || '',
      });
    } else if (order.type === OrderType.MOVEMENT) {
      setInfo({
        comment: (orderWithInfo.info as MovementOrderInfo).coordinatorComment || '',
        vehicle: (orderWithInfo.info as MovementOrderInfo).vehicle || '',
      });
    }
  };

  const expandDetails = useCallback(
    () => {
      if (!showDetails) {
        getInfo();
      }
      setShowDetails(!showDetails);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showDetails],
  );

  useEffect(() => {
    setUserOptions(workorderAssignableUsers);
  }, [workorderAssignableUsers]);

  return (
    <div
      ref={cardRef}
      className={classNames('work-order-card', order?.priority?.toLowerCase(), {
        'light-version': !user,
        'not-assigned': !order.assignedUsers?.length,
      })}
    >

      <AddDeceasedQRModal
        order={orderWithInfo}
        show={showAddDeceasedQRModal}
        onHide={() => setShowAddDeceasedQRModal(false)}
        onSuccess={() => {
          setShowAddDeceasedQRModal(false);
          callBack?.();
        }}
      />
      <FinishOrder
        order={orderWithInfo}
        show={showFinishOrder}
        onHide={() => setShowFinishOrder(false)}
        onSuccess={() => {
          setShowFinishOrder(false);
          callBack?.();
        }}
      />
      <CreateUpdate
        callBack={callBack}
        show={showEdit}
        title={t('order.edit')}
        workorderId={order.id}
        onHide={() => setShowEdit(false)}
      />
      <Modal
        buttons={buttonsAddUsersModal}
        className="workorder-card-modal"
        show={showAssignModal}
        title={t('user.assignUser')}
        showCancel
        onHide={() => {
          setShowAssignModal(false);
          setInfo({});
        }}
      >
        <AssignUsers
          assignedUsers={assignedUsers}
          info={info}
          setAssignedUsers={setAssignedUsers}
          setInfo={setInfo}
          setUserOptions={setUserOptions}
          userOptions={userOptions}
          workorder={order}
          usersRequestedFromFather
        />
        {loading && <Loader />}
      </Modal>

      <Modal
        buttons={modals[chosenModal].buttons}
        className="workorder-card-modal"
        show={showConfigModal}
        showCancel={modals[chosenModal].showCancel}
        title={modals[chosenModal].title}
        onHide={closeAndResetModal}
      >
        {showConfigModal && !loading && modals[chosenModal].body}
        {loading && <Loader />}
      </Modal>

      {isTvView && (
      <div>
        <WorkshopCardHeader
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          order={(order as any)}
        />
      </div>
      )}
      {!isTvView && (
      <div>
        <div className="order-type">
          <CustomIcon icon={order.type} />
          {t(`service.workorders.${order.type}`)}
        </div>
        <div className="order-record">
          <Folder />
          <div>
            <h6>{order.erpId || ''}</h6>
            {formatName({
              firstSurname: order.deceasedFirstSurname,
              name: order.deceasedName,
              secondSurname: order.deceasedSecondSurname,
            })}
          </div>
          {order?.status !== StatusCodes.COMPLETED && !isTvView && (
          <div className="icon-more-actions">
            <MoreActions
              options={getMoreActionsOptions()}
              toggleIcon={
                <MoreVertical className="icon-in-right" size={40} />
                }
            />
          </div>
          )}
        </div>
      </div>
      )}
      <div className="d-flex flex-column">
        <div className="order-info">
          <span className="order-date">{displayDate}</span>
          <div className="d-flex align-items-center">
            <User />
            <div className="d-flex align-items-center">
              {order?.assignedUsers?.length > 0
                ? order.assignedUsers
                  .map((x) => `${x.name} ${x.firstSurname}`)
                  .join(', ')
                : t('order.notAssigned')}
              {roleInfo.isWorkshopAdmin
                && order?.status !== StatusCodes.COMPLETED && (
                  <div className="icon-add-user">
                    <Button
                      color="transparent"
                      id="button-add-assigned"
                      testid="button-add-assigned"
                      onClick={() => {
                        setAssignedUsers((orderWithInfo as Workorder).assignedUsers);
                        getInfo();
                        fetchWorkorderAssignableUsers(order.id.toString());
                        setShowAssignModal(true);
                      }}
                    >
                      {operatorAssigned ? (
                        <MinusCircle className="icon-in-right" size={18} />
                      ) : (
                        <PlusCircle className="icon-in-right" size={18} />
                      )}
                    </Button>
                  </div>
              )}
            </div>
          </div>
        </div>
        {!isTvView && (
          <Button
            className={classNames(
              'order-more-data',
              order?.priority?.toLowerCase(),
              {
                'not-assigned': !order.assignedUsers?.length,
              },
            )}
            color="transparent"
            onClick={expandDetails}
          >
            <span className="order-comment">{t('order.details.details')}</span>
            <ChevronDown
              className={
                showDetails
                  ? classNames('chevron-down', 'rotate')
                  : 'chevron-down'
              }
            />
          </Button>
        )}
        {!isTvView && (
          <WorkOrderInfo
            expanded={showDetails}
            loading={loading}
            order={orderWithInfo}
          />
        )}
      </div>
      {loadingModal && <Loader />}
    </div>
  );
};

export default WorkOrderCard;
